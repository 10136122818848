export const sumTabNames = [
  {
    name: 'myScore',
    label: '我的得分'
  },
  {
    name: 'organize',
    label: '组织评比'
  }
]
export const appraisalTabNames = [
  {
    name: 'review',
    label: '待我考评'
  },
  {
    name: 'evaluation',
    label: '过往考评'
  }
]
